import axios from 'axios'
import store from '../store'

const backend = axios.create({
  baseURL: 'https://rechnungsverwaltung.stall-unserweidlinger.at/api/v1/',
  timeout: 1000,
})

backend.interceptors.request.use(config => {
  if (store.getters.getToken) {
    config.headers['Authorization'] = store.getters.getToken
  }
  return config
}, error => {
  return Promise.reject(error)
})

export function postLogin ({ username, password }) {
  return backend.post('auth/login', {
    username,
    password
  })
}


