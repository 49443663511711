import Vue from 'vue';
import Vuex from 'vuex';
import mutations from './mutation'
import * as actions from './actions'
import * as getters from './getters'

import rechnung from './modules/Rechnungen/index';
import kunde from './modules/Kunden/index';
import produkt from './modules/Produkte/index';
import zahlungsmethode from './modules/Zahlungsmethoden/index';

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    demo: 0,
    token: undefined,
    username: undefined,
  },
  modules: {
    zahlungsmethode,
    rechnung,
    kunde,
    produkt
  },
  mutations,
  actions,
  getters,
  strict: process.env.NODE_ENV !== 'production'
})