<template>
  <div class="container">
         <h5 class="">Ausgestellte Rechnungen</h5>
            <v-row 
              justify="start"
            >
              <v-col
              cols="1">
                <p class="v-text-field text-left">Von:</p>
              </v-col>
              <v-col
                cols="4"
                class="mr-0 pr-0"
                >
                <v-dialog
                  ref="datePickFromRe"
                  v-model="datePickFromRe"
                  :close-on-content-click="false"
                  :return-value.sync="dateFromRE"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFromRE"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      type="date"
                      class="datePicki mt-2"
                      rounded
                      solo
                      dense
                      background-color="white"
                      prepend-inner-icon="mdi-calendar-month-outline"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateFromRE"
                    scrollable
                    no-title
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="#007EEF"
                      @click="datePickFromRe = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="#09B760"
                      @click="$refs.datePickFromRe.save(dateFromRE)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col
              cols="1">
                <p class="v-text-field text-left">Bis:</p>
              </v-col>
              <v-col
                cols="4"
                class="mr-0 pr-0"
                >
                <v-dialog
                  ref="datePickToRe"
                  v-model="datePickToRe"
                  :close-on-content-click="false"
                  :return-value.sync="dateToRE"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="datePicki mt-2"
                      v-model="dateToRE"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      type="date"
                      rounded
                      color="#001B30"
                      solo
                      dense
                      background-color="white"
                      prepend-inner-icon="mdi-calendar-month-outline"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateToRE"
                    scrollable
                    no-title 
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="#007EEF"
                      @click="datePickToRe = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="#09B760"
                      @click="$refs.datePickToRe.save(dateToRE)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
            <p class="text-left listTitle">Bezahlstatus</p>
            <v-list 
                two-line
                class="kundenList"
            >
                <v-list-item-group
                multiple
                >
                        <div v-for="(rechnungsObjekt,index) in filteredList" :key="index" >                                
                          <v-list>
                            <v-list-group
                              
                              append-icon
                            >
                              <template v-slot:activator>
                                <v-list-item-content>
                                  <v-list-item-subtitle class="subtitelLiRe text-left" v-text="format_date(rechnungsObjekt.leistungsdatumFrom)"></v-list-item-subtitle>
                                  <v-list-item-title class="titelLiRe text-left gruen" v-text="rechnungsObjekt.summe + ' €' "></v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-content>
                                  <v-list-item-title class="titelLi titelLiRe text-right" v-text="rechnungsObjekt.kunde.firma"></v-list-item-title>
                                  <v-list-item-subtitle class="subtitelLiRe text-right" v-text="rechnungsObjekt.kunde.vorname +' ' + rechnungsObjekt.kunde.nachname "></v-list-item-subtitle>
                                </v-list-item-content>
                              </template>
                              <v-list-item>
                                <v-list-item-content>
                                  <div class="row kundenListIcon">
                                    <div class="col-4">
                                      <v-btn
                                        fab
                                        x-small
                                        class="kundenListIcon"
                                      >
                                        <v-icon class="kundenListIcon" color="#007EEF">mdi-download-outline</v-icon>
                                      </v-btn>
                                      <p class="blau">PDF-Download</p>
                                    </div>
                                    <div class="col-4">
                                      <v-btn
                                        fab
                                        x-small
                                        :to="'/rechnung/'+rechnungsObjekt.id"
                                        class="kundenListIcon"
                                      >
                                        <v-icon class="kundenListIcon" color="#09B760">mdi-pencil-outline</v-icon>
                                      </v-btn>
                                      <p class="gruen">Rechnung bearbeiten</p>
                                    </div>
                                    <div class="col-4">
                                      <v-btn
                                        fab
                                        x-small
                                        class="kundenListIcon"
                                      >
                                        <v-icon class="kundenListIcon">mdi-close</v-icon>
                                      </v-btn>
                                      <p>Rechnung löschen</p>
                                    </div>
                                  </div>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-group>
                          </v-list>
                          <v-divider></v-divider>
                        </div>
              </v-list-item-group>
          </v-list>
          <v-btn 
            class="NeuerBelegBTN my-6 mt-15 "
            to="/kunde/add"
            rounded
            color="#35EF96"
          >
          <v-icon
            color="black"
            left
            >
            mdi-pencil-outline
          </v-icon>
          Rechnungslayout anpassen</v-btn>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: 'Rechnungen',
    data() {
      return {
        leistungsdatum:'',
        datePickFromRe:false,
        datePickToRe:false,
        dateFromRE:moment().subtract(21, 'days').format('yyyy-MM-DD'),
        dateToRE:moment().format('yyyy-MM-DD'),
        search:'',
      }
    },
    created() {
        this.getDatesFromStorage();
        // this.$store.dispatch('getRechnungsObjekte');
        // this.$store.dispatch('getKundenObjekte');
        // this.$store.dispatch('getProduktObjekte');
    },
    computed:{
      filteredList(){
        return this.rechnungsObjekts.filter(rechnungsObjekts=>{
          if(rechnungsObjekts.einnahme===true){
            var a =rechnungsObjekts.kunde.vorname +' '+ rechnungsObjekts.kunde.nachname +' '+  rechnungsObjekts.kunde.firma;
          return a.toLowerCase().includes(this.search.toLowerCase())
          }
        })
      },
      rechnungsObjekts(){
        return this.$store.getters.rechnungsObjekts;
      },
    },
    mounted() {
      this.leistungsdatum=this.rechnungsObjekts.leistungsdatumFrom;
      this.leistungsdatum=moment(this.leistungsdatum).format('DD.MM.YYYY');
      console.log(this.leistungsdatum);
    },
    methods: {
      dateFormat(date){
            let newFormat=moment(date).format('DD.MM.YYYY');
            return newFormat;
        },
        format_date(value){
         if (value) {
           return moment(String(value)).format('DD.MM.YYYY')
          }
      },
      updateBillByDate(){
        this.$store.dispatch('getRechnungsObjekteByDate',{
                  fromDate:this.dateFromRE,
                  toDate:this.dateToRE,
              });
      },
      getDatesFromStorage(){
        if(localStorage.getItem('fromDate')!= null && localStorage.getItem('toDate')!= null){
        this.dateFromRE=localStorage.getItem('fromDate');
        this.dateToRE=localStorage.getItem('toDate');
      }else{
        this.updateBillByDate();
      }
      }
    },
    watch:{
    dateFromRE:function(){
        this.updateBillByDate();
    },
    dateToRE:function(){
        this.updateBillByDate();
    },
  },
}
</script>

<style scoped >
    .actions {
    width:150px;
    padding:0;
    margin:0;
    list-style:none;
    opacity:0;
    transform:translateY(-50%) translateX(-50%);
    position:absolute;
    top:60%;left:50%;
    z-index:1;
    transition:all .3s ease 0s
  }
  .card:hover .actions {
    opacity: 1;
    top: 50%;
  }
  .card .actions li {
    display: inline-block;
  }
  .card .actions li button {
    color: #fff;
    background-color: rgb(52,73,94);
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    height: 40px;
    width: 40px;
    margin: 0 2px;
    display: block;
    position: relative;
    transition: all .3s ease-in-out;
    padding: 0;
  }

  .card .actions li button:hover {
    color: #fff;
    background-color: rgb(65,184,131);
  }

  .card .actions li button:before {
    content: attr(data-tip);
    color: #fff;
    background-color: rgb(65,184,131);
    transition: all .3s ease-in-out;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 20px;
    padding: 1px 5px;
    white-space: nowrap;
    opacity: 0;
    transform: translateX(-50%);
    position: absolute;
    left: 50%;
    top: -30px
  }

  .card .actions li button:hover:before {
    opacity: 1
  }
  .card-title{
    font-weight: 600;
  }
  .v-text-field{
  font-size:11px !important;
}
::v-deep .datePicki .v-icon {
  font-size: 14px !important;
  color: #001B30;
}
::v-deep .datePicki .v-input__slot {
  padding-left:5px !important;
}
::v-deep .v-input__control{
  min-height: auto !important;
}
.listTitle{
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase  !important;
  color:#09B760;
  margin-bottom: 0px;
}
.titelLiRe{
  font-size:  16px;
  font-weight: 600;
}
.subtitelLiRe{
  font-size:  12px;
  font-weight: 500;
}
.v-list-item__content{
    padding: 0px !important;
}
.v-list{
    padding: 0px !important;
}
</style>
