import axios from 'axios';

const state={
    rechnungsObjekts:[]
}
const mutations={
    UPDATE_RECHNUNGS_OBJEKTE (state,payload){
        state.rechnungsObjekts=payload;
    },
    PATCH_RECHNUNGSOBJEKT (state,rechnungsObjekt){
        state.rechnungsObjekt=rechnungsObjekt;
    },
    ADD_RECHNUNGSOBJEKT(state, rechnungsObjekt) {
        state.rechnungsObjekt = rechnungsObjekt;
    },
    UPDATE_RECHNUNGS_OBJEKTE_BYDATE (state,payload){
        state.rechnungsObjekts=payload;
    },
}
const actions={
    getRechnungsObjekte({commit}){
        axios.get('/rechnungen')
            .then((response)=>{
                commit('UPDATE_RECHNUNGS_OBJEKTE', response.data);
            })
    },
    getRechnungsObjekteByDate({commit},dates){
        axios.get(`/rechnungen/dates/${dates.fromDate}/${dates.toDate}`,dates)
            .then((response) => {
                localStorage.setItem('fromDate', dates.fromDate);
                localStorage.setItem('toDate',dates.toDate);
                commit('UPDATE_RECHNUNGS_OBJEKTE_BYDATE', response.data);
            })
    },
    getRechnungsObjekteByDatepos({commit},dates){
        axios.get(`/rechnungen/dates/${dates.fromDate}/${dates.toDate}/pos`,dates)
            .then((response) => {
                localStorage.setItem('fromDate', dates.fromDate);
                localStorage.setItem('toDate',dates.toDate);
                commit('UPDATE_RECHNUNGS_OBJEKTE_BYDATE', response.data);
            })
    },
    getRechnungsObjekteByDateneg({commit},dates){
        axios.get(`/rechnungen/dates/${dates.fromDate}/${dates.toDate}/neg`,dates)
            .then((response) => {
                localStorage.setItem('fromDate', dates.fromDate);
                localStorage.setItem('toDate',dates.toDate);
                commit('UPDATE_RECHNUNGS_OBJEKTE_BYDATE', response.data);
            })
    },
    patchRechnungsObjekt({ commit }, rechnungsObjekt) {
        return new Promise((resolve, reject) => {
            axios.patch(`/rechnungen/${rechnungsObjekt.id}`,rechnungsObjekt)
            .then((response) => {
                console.log(response.data);
                commit('PATCH_RECHNUNGSOBJEKT', response.data);
                resolve(response)
            }).catch((error) => {
                reject(error.response.data.errors)
            })
        })
    },
    addRechnungsObjekt({ commit }, rechnungsObjekt) {
        return new Promise((resolve, reject) => {
            axios.post(`/rechnungen`, rechnungsObjekt)
            .then((response) => {
                console.log(response.data);
                commit('ADD_RECHNUNGSOBJEKT', response.data);
                resolve(response)
            }).catch((error) => {
                reject(error.response.data.errors)
            })
        })
    }
}
const getters={
    rechnungsObjekts:state=>state.rechnungsObjekts,
    rechnungsObjektById: state => id => state.rechnungsObjekts.find(rechnungsObjekt => rechnungsObjekt.id === id),
    
}
const rechnungsModule={
    state,
    mutations,
    actions,
    getters
}

export default rechnungsModule;