<template>
    <div class="container mt-5">
        <div class="row">
            <v-btn 
                value="favorites" 
                class="btnZ ml-2 mb-5 mt-2" 
                color="white"
                rounded
                @click="$router.go(-1)"
            >
                <v-icon
                color="#007EEF"
                left
                >
                mdi-keyboard-backspace
                </v-icon>
                <span>Zurück</span>
            </v-btn>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="row no-gutters">
                    <h5 v-if="produktObjekt==undefined" class="col-12">
                        Neues Produkt eingeben
                    </h5>
                    <h5 v-else class="col-12 mb-0">
                        {{produktObjekt.titel}} bearbeiten
                    </h5>
                </div>
            </div>
            <div class="col-12">
                    <!-- <div class="row"> -->
                        <div class="col-12">
                                <div class="row no-gutters">
                                        <h5 class="card-title"  v-if="produktObjekt===undefined">
                                            <p class="inputBesch1 text-secondary mt-0 mb-1 float-left">Titel</p>
                                            <v-textarea 
                                                v-model="Titel" 
                                                placeholder="Titel" 
                                                class="form-control inputBesch1 py-0" 
                                                rows="1"
                                                row-height="10" 
                                                auto-grow 
                                                rounded
                                            >
                                            </v-textarea>
                                            <p class="inputBesch1 text-secondary mt-0 mb-1 float-left">Preis</p>
                                            <v-textarea 
                                                v-model="Preis" 
                                                placeholder="Preis" 
                                                class="form-control inputBesch1 py-0" 
                                                rows="1"
                                                row-height="10" 
                                                auto-grow 
                                                rounded
                                            >
                                            </v-textarea>
                                        </h5>
                                        <h5 v-else>
                                            <div v-if="produktObjekt">
                                                <p class="inputBesch1 text-secondary mt-0 mb-1 float-left">Titel</p>
                                                <v-textarea 
                                                    v-model="Titel" 
                                                    placeholder="Titel" 
                                                    class="form-control inputBesch1 py-0" 
                                                    rows="1"
                                                    row-height="10" 
                                                    auto-grow 
                                                    rounded
                                                >
                                                </v-textarea>
                                                <p class="inputBesch1 text-secondary mt-0 mb-1 float-left">Preis</p>
                                                <v-textarea 
                                                    v-model="Preis" 
                                                    placeholder="Preis" 
                                                    class="form-control inputBesch1 py-0" 
                                                    rows="1"
                                                    row-height="10" 
                                                    auto-grow 
                                                    rounded
                                                >
                                                </v-textarea>
                                            </div>   
                                        </h5>
                                </div>
                                <v-btn 
                                    class="BtnGross mr-2 mt-3 "
                                    color="#35EF96"
                                    rounded
                                    @click="save(produktObjekt)"
                                >
                                    <i class="far fa-save mr-1"></i>Abspeichern
                                </v-btn>
                        </div>
                    <!-- </div> -->
                </div>
        </div> 
        <div class="col-12">
            <v-snackbar
                v-model="snackbar.show"
                rounded="pill"
                :color="snackbar.color"
                :multi-line="snackbar.multi"
                class="snackbarText"
                @click="snackbar.show = false"
            >  
                {{snackbar.message}}
        </v-snackbar>
        </div> 
    </div>
</template>

<script>
import moment from 'moment';
export default {
    name:'ProduktObjekt',
    props:['id'],
    components:{    
    },
    computed:{
        produktObjekt(){
           if(this.id!="add"){
               return {...this.$store.getters.produktsObjektById(this.id)};
           }else{
               return undefined;
           }
        },
        
    },
    mounted() {
        this.datenladen();
    },
    data(){
        return{
            snackbar:{
                show: false,
                message: null,
                color: '#001854',
                multi:true
            },
            Titel:'',
            Preis:'',
            datenladenBool:true
        }
    },
    methods: {
        datenladen(){
            if(this.datenladenBool){
                if(this.produktObjekt!==undefined){
                    this.Titel=this.produktObjekt.titel;
                    this.Preis=this.produktObjekt.preis;
                 } 
            }
            
        },
        save(produktObjekt){
            if(this.id=="add"){
                this.$store.dispatch('addProduktObjekt',{
                    titel:this.Titel,
                    preis:this.Preis,
                }).then(()=>{
                      this.snackbar={
                          message:'Erfolgreich abgespeichert',
                          color:'#001854',
                          show:true,
                      }
                  })
                  .catch(error=>{ // eslint-disable-line no-unused-vars
                          this.snackbar={
                          message:'Ups da ist was schief gelaufen',
                          color:'red',
                          show:true,
                    }
                });
            }else{
                console.log(produktObjekt.id)
                this.produktObjekt.titel=this.Titel;
                this.produktObjekt.preis = this.Preis;
                this.$store.dispatch('patchProduktObjekt',produktObjekt);
                console.log(produktObjekt)
            }
        },
        rechnungsObjekt(v){
            if(this.id!="add"){
               return {...this.$store.getters.rechnungsObjektById(Number(v))};
           }else{
               return undefined;
           }
        },
        dateFormat(date){
            let newFormat=moment(date).format('DD.MM.YYYY');
            return newFormat;
        }

    }
}
</script>

<style>
    .icon{
        color:lightgrey !important;
    }
</style>