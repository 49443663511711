<template>
    <div>
        <v-bottom-navigation permanent left class="mt-2 teal darken-4">
                <v-btn class="btn mt-2 " @click="$router.push('/').catch(()=>{});">
                    <span>Posand</span>
                    <v-icon>mdi-bill</v-icon>
                </v-btn>
                <v-btn class="btn mt-2 " @click="$router.push('/rechnungsuebersicht').catch(()=>{});" >
                    <span>Rechnungen</span>
                 </v-btn>
                <v-btn class="btn mt-2" @click="$router.push('/kundenuebersicht').catch(()=>{});">
                    <span>Kunden</span>
                 </v-btn>
                 <v-btn class="btn mt-2" @click="$router.push('/produktuebersicht').catch(()=>{});">
                    <span>Produkte</span>
                 </v-btn>
                <v-btn v-if="isEingeloggt" class="btn mt-2" @click="$router.push('/login').catch(()=>{});">
                    <span>Login</span>
                 </v-btn>
                 <v-btn v-else class="btn mt-2" @click="$router.push('/logout').catch(()=>{});">
                    <span>Logout</span>
                 </v-btn>
        </v-bottom-navigation>
    </div>
    
</template>

<script>
export default {
    name:"Navbar",
    data() {
        return {
            isEingeloggt:false
        }
    },
    mounted() {
        this.isEingeloggt=this.$store.state.isAuth; 
    }
}
</script>

<style scoped>
    a{
        color:aliceblue !important;
    }

    a:hover{
        opacity: .7;
        transition:all .100s ease 0;
    }
    span{
        font-size: 14px;
    }
</style>