<template>
    <div class="container">
        <h5 class="">Produkt- & Dienstleistungsübersicht</h5>
        <h6>nach Einnahmen 2021</h6>
            <v-list 
                two-line
                class="kundenList"
            >
                <v-list-item-group
                multiple
                >
                        <div 
                          v-for="produktObjekt in produktObjekts"
                          :key="produktObjekt.id" >                                
                          <v-list>
                            <v-list-group
                              
                              append-icon
                            >
                              <template v-slot:activator>
                                <v-list-item-content>
                                  <v-list-item-title class="text-left titelLi" v-text="produktObjekt.titel"></v-list-item-title>
                                  <v-list-item-subtitle class="text-left" v-text="'ID '+produktObjekt.id"></v-list-item-subtitle>

                                </v-list-item-content>
                                <v-list-item-action>
                                  <!-- <v-list-item-title class="summeKund" v-text="'+' +getSumme(produktObjekt)+'€'"></v-list-item-title> -->
                                </v-list-item-action>
                              </template>
                              <v-list-item>
                                <v-list-item-content>
                                  <div class="row kundenListIcon">
                                    <div class="col-4">
                                      <v-btn
                                        fab
                                        x-small
                                        :to="'/produkt/'+ produktObjekt.id"
                                        class="kundenListIcon"
                                      >
                                        <v-icon class="kundenListIcon" color="#007EEF">mdi-eye-outline</v-icon>
                                      </v-btn>
                                      <p class="blau">Produkt ansehen</p>
                                    </div>
                                    <div class="col-4">
                                      <v-btn
                                        fab
                                        x-small
                                        :to="'/produkt/'+produktObjekt.id"
                                        class="kundenListIcon"
                                      >
                                        <v-icon class="kundenListIcon" color="#09B760">mdi-pencil-outline</v-icon>
                                      </v-btn>
                                      <p class="gruen">Produkt bearbeiten</p>
                                    </div>
                                    <div class="col-4">
                                      <v-btn
                                        fab
                                        x-small
                                        class="kundenListIcon"
                                      >
                                        <v-icon class="kundenListIcon">mdi-close</v-icon>
                                      </v-btn>
                                      <p>Kunde löschen</p>
                                    </div>
                                  </div>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-group>
                          </v-list>
                          <v-divider></v-divider>
                        </div>
              </v-list-item-group>
          </v-list>
          <v-btn 
            class="NeuerBelegBTN my-6 "
            to="/produkt/add"
            rounded
            color="#35EF96"
          >
          + Neues Produkt/ Service</v-btn>
    </div>
</template>

<script>

export default {
    name: 'Produkte',
    data() {
      return {
        leistungsdatum:''
      }
    },
    created() {
        // this.$store.dispatch('getRechnungsObjekte');
        // this.$store.dispatch('getKundenObjekte');
        // this.$store.dispatch('getProduktObjekte');

        
    },
    computed:{
      produktObjekts(){
        return this.$store.getters.produktsObjekts;
      },
    } 
}
</script>

<style scoped >
    .actions {
    width:150px;
    padding:0;
    margin:0;
    list-style:none;
    opacity:0;
    transform:translateY(-50%) translateX(-50%);
    position:absolute;
    top:60%;left:50%;
    z-index:1;
    transition:all .3s ease 0s
  }
  .card:hover .actions {
    opacity: 1;
    top: 50%;
  }
  .card .actions li {
    display: inline-block;
  }
  .v-list-item__content{
    padding: 0px !important;
  }
  .v-list{
    padding: 0px !important;
  }
  .card .actions li button {
    color: #fff;
    background-color: rgb(52,73,94);
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    height: 40px;
    width: 40px;
    margin: 0 2px;
    display: block;
    position: relative;
    transition: all .3s ease-in-out;
    padding: 0;
  }

  .card .actions li button:hover {
    color: #fff;
    background-color: rgb(65,184,131);
  }

  .card .actions li button:before {
    content: attr(data-tip);
    color: #fff;
    background-color: rgb(65,184,131);
    transition: all .3s ease-in-out;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 20px;
    padding: 1px 5px;
    white-space: nowrap;
    opacity: 0;
    transform: translateX(-50%);
    position: absolute;
    left: 50%;
    top: -30px
  }

  .card .actions li button:hover:before {
    opacity: 1
  }
  .card-title{
    font-weight: 600;
  }

</style>
