<template>
  <div>
       <v-app-bar
        bottom
        rounded
        fixed
        elevate-on-scroll
        >
            <v-tabs
            grow
            icons-and-text
            slider-color="#35EF96"
            color="#001B30"
            v-model="tab"
            >
                <v-tab
                    class="bottomMenuTab"
                    >
                    Übersicht                    
                    <v-icon
                    color='#001B30'
                    >
                    mdi-format-list-bulleted
                    </v-icon>
                </v-tab>
                <v-tab
                    class="bottomMenuTab"
                    >
                    Reporting
                    <v-icon
                    color='#001B30'
                    >mdi-chart-donut-variant</v-icon>
                </v-tab>
            </v-tabs>
  </v-app-bar>
  <v-row 
      justify="start"
      >
        <v-col
        cols="1">
          <p class="v-text-field text-left">Von:</p>
        </v-col>
        <v-col
          cols="4"
          >
          <v-dialog
            ref="datePickFrom"
            v-model="datePickFrom"
            :close-on-content-click="false"
            :return-value.sync="dateFrom"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateFrom"
                readonly
                v-bind="attrs"
                v-on="on"
                type="date"
                class="datePicki mt-2"
                rounded
                solo
                dense
                background-color="white"
                prepend-inner-icon="mdi-calendar-month-outline"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateFrom"
              scrollable
              no-title
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="#007EEF"
                @click="datePickFrom = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="#09B760"
                @click="$refs.datePickFrom.save(dateFrom)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col
        cols="1">
          <p class="v-text-field text-left">Bis:</p>
        </v-col>
        <v-col
          cols="4"
          >
          <v-dialog
            ref="datePickTo"
            v-model="datePickTo"
            :close-on-content-click="false"
            :return-value.sync="dateTo"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="datePicki mt-2"
                v-model="dateTo"
                readonly
                v-bind="attrs"
                v-on="on"
                type="date"
                rounded
                color="#001B30"
                solo
                dense
                background-color="white"
                prepend-inner-icon="mdi-calendar-month-outline"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateTo"
              scrollable
              no-title 
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="#007EEF"
                @click="datePickTo = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="#09B760"
                @click="$refs.datePickTo.save(dateTo)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
  <v-tabs-items :value="tab" touchless>
      <v-tab-item>
            <v-row>
                <v-col
                    cols="7">
                    <v-text-field 
                        label="Durchsuche Namen" 
                        prepend-inner-icon="search" 
                        rounded
                        color="#35EF96"
                        solo 
                        v-model="search"  
                        class="suche"
                        right
                    >
                     </v-text-field>
                </v-col>
                <v-col cols="3">
                </v-col>
                <v-col
                  cols="2"
                  class="mt-1 "
                >
                  <v-dialog
                    v-model="filterEin"
                    persistent
                    max-width="290"
                  >
                    <template v-slot:activator="{ on, attrs }">
                       <v-icon color="#001B30" v-bind="attrs" v-on="on">mdi-filter-outline</v-icon>
                    </template>
                    <v-card >
                      <v-card-title class="text-h5">
                       Filtern nach:
                      </v-card-title>
                      <v-card-text>
                        <v-radio-group
                          class="radioGroup"
                          row
                          rounded
                          v-model="active"
                        >
                          <v-radio
                            label="Alle"
                            value=""
                          ></v-radio>
                          <v-radio
                            label="Nur Einnahmen"
                            value="pos"
                          ></v-radio>
                          <v-radio
                            label="Nur Ausgaben"
                            value="neg"
                          ></v-radio>
                        </v-radio-group>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="#007EEF"
                          text
                          @click="filterEin = false"
                        >
                          Abbrechen
                        </v-btn>
                        <v-btn
                          color="#35EF96"
                          text
                          @click="filterEinAus(active)"
                        >
                          Bestätigen
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
            </v-row>
            <v-list 
                two-line
                class="rechnungsList"
            >
                <v-list-item-group
                multiple
                >
                    <template>
                        <div class="container">
                            <div v-for="(rechnungsObjekt,index) in filteredList" :key="index">
                                <v-list-item >
                                <template >
                                    <v-list-item-content>
                                    <v-list-item-subtitle
                                        class="text-left"
                                        v-text="format_date(rechnungsObjekt.leistungsdatumFrom)"
                                        >
                                        </v-list-item-subtitle>
                                    <v-list-item-title class="text-left" :class="{ 'einnahme': rechnungsObjekt.einnahme, 'ausgabe': !rechnungsObjekt.einnahme }" >
                                        {{styleSum(rechnungsObjekt)}}
                                    </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                    <v-list-item-action-text class="listBesch" v-text="rechnungsObjekt.kunde.firma"></v-list-item-action-text>
                                    <v-list-item-action-text v-text="rechnungsObjekt.kunde.vorname +' ' + rechnungsObjekt.kunde.nachname "></v-list-item-action-text>
                                    </v-list-item-action>
                                </template>
                                </v-list-item>
                                <v-divider v-if="index < rechnungsObjekts.length -1"></v-divider>
                            </div>

                        </div>
                    </template>
                </v-list-item-group>
            </v-list>
            <v-divider class="MainDivider"></v-divider>
            <p class="text-left mt-2 mb-0">SALDO {{format_date(dateFrom)}} - {{format_date(dateTo)}} </p>
            <p class="text-left Saldo">{{calculateSaldo(Saldo)}} € </p>
            <v-btn 
                class="NeuerBelegBTN mb-2"
                to="/rechnung/add"
                rounded
                color="#35EF96"
            >
            + Neuer Beleg</v-btn>
      </v-tab-item>
      <v-tab-item>
          <v-row
            align="center"
            justify="center">
            <v-col cols="11" class="mt-1">
              <v-card
                elevation="9"
                class="px-4 py-4">
                <v-sheet>
                  <div id="chart">
                    <apexchart type="area" :options="chartOptions" :series="series"></apexchart>
                  </div>
                </v-sheet>
              </v-card>  
            </v-col>
            <v-col cols="11">
              <v-card
                elevation="9"
                class="mb-10"
                align="center"
                justify="center">
                <v-sheet>
                  <div id="chart" class="row">
                    <p class="col-12 my-0 pb-0 pieTitle">Anteile Einnahmen & Ausgaben</p>
                    <div class="col-12 pt-0 px-0 chartPie">
                      <apexchart  class="px-0 mx-0" type="donut" :options="chartOptions1" :series="series1"></apexchart>
                    </div>
                    <div class="row">
                      <div class="col-6 p-0 chartLabelL">
                        <p class="mb-0">
                          Ausgaben
                        </p>
                        <p class="m-0 blau">
                          -{{this.chartDataDonut.y}}€
                        </p>
                    </div>
                    <div class="col-6 p-0 chartLabelR">
                        <p class="mb-0">
                          Einnahmen
                        </p>
                        <p class="m-0 gruen">
                          +{{this.chartDataDonut.x}}€
                        </p>
                    </div>
                    </div>
                  </div>
                </v-sheet>
              </v-card>  
            </v-col>
          </v-row>
      </v-tab-item>
  </v-tabs-items>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'Startseite',
  props: {
    msg: String
  },
  components:{
   
  },
  data(){
    return{
        active:"active",
        filterEin:false,
          chartOptions: {
            colors:['#09B760'],
            chart: {
              toolbar:{
              show:false,
            },
              type: 'area',
              height: 50,
              zoom: {
                enabled: false
              }
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'smooth',
              width:2,
              lineCap: 'butt',
            },
            
            title: {
              text: 'Zeitliche Entwicklung',
              align: 'left',
              style: {
                color: '#001B30',
                fontSize: '14px',
                fontFamily: 'Montserrat',
                fontWeight: 700,
                marginTop:10,
          },
            },
            subtitle: {
            },
            labels: "12",
            xaxis: {
              type: 'datetime',
            },
            yaxis: {
              opposite: true
            },
            legend: {
              horizontalAlign: 'left'
            }
          },
          series1: [],
          chartOptions1: {
            colors:['#35EF96','#007EEF'],
            labels:['Einnahmen','Ausgaben'],
            chart: {
              width: 200,
              type: 'donut',
            },
            plotOptions:{
              pie: {
                donut: {
                  labels: {
                    show: true,
                    name:['einnahme','ausgabe'],
                    total: {
                      show: true,
                      label: '',                      
                      formatter: () => {
                        if(this.calculateSaldo(this.Saldo)>=0){
                          return ['Gewinn', '+'+this.calculateSaldo(this.Saldo) + '€']
                        }else{
                          return ['Verlust', this.calculateSaldo(this.Saldo) + '€']
                        }
                      },
                    },
                    value:{
                      show:true,
                      fontSize:'14px',
                      fontFamily:'Montserrat',
                      fontWeight:'700',
                      offsetY:0,
                    }
                  },
                }
              }
            },
            dataLabels: {
              enabled: false,
            },
            title: {
              text: '',
              align: 'left',
              style: {
                color: '#001B30',
                fontSize: '14px',
                fontFamily: 'Montserrat',
                fontWeight: 700,
                marginTop:10,
            },
            },
            subtitle: {
            },
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  show: false
                },
              }
            }],
            legend: {
              position: 'right',
              offsetY: 0,
              height: 230,
            },
          },
      einAusCheck:true,
      tab:null,
      search:'',
      datePickFrom:false,
      datePickTo:false,
      dateFrom:moment().subtract(365, 'days').format('yyyy-MM-DD'),
      dateTo:moment().format('yyyy-MM-DD'),
      Saldo:0,
      chartData:[],
      chartDataDonut:[]
    }
  },
  computed:{
    series: function() {
      return [{
           name: 'Zeitliche Entwicklung',
           data: this.chartData
       }]
      },
        rechnungsObjekts(){
            return this.$store.getters.rechnungsObjekts;
        },
        filteredList(){
          return this.rechnungsObjekts.filter(rechnungsObjekts=>{
            var a =rechnungsObjekts.kunde.vorname +' '+ rechnungsObjekts.kunde.nachname +' '+  rechnungsObjekts.kunde.firma;
            return a.toLowerCase().includes(this.search.toLowerCase() )
          })
        },
    }, 
    methods: {
      filterEinAus(active){
          this.filterEin=false;
          if(active===0){
            this.$store.dispatch('getRechnungsObjekteByDate',{
              fromDate:this.dateFrom,
              toDate:this.dateTo,
            });
        }
        if(active==='pos'){
            this.$store.dispatch('getRechnungsObjekteByDatepos',{
              fromDate:this.dateFrom,
              toDate:this.dateTo,
            });
        }
        if(active==='neg'){
            this.$store.dispatch('getRechnungsObjekteByDateneg',{
              fromDate:this.dateFrom,
              toDate:this.dateTo,
            });
        }
      },
      getData(){
        this.chartData=[];
        this.series1=[];
        
        this.chartDataDonut={x:0,y:0};
        this.chartData.push({x:moment(this.dateFrom).format('MM.DD.YYYY') ,y:0})
        for(var i in this.rechnungsObjekts){
          var newChartData={x:'',y:0};
          newChartData.x=moment(this.rechnungsObjekts[i].leistungsdatumFrom).format('MM.DD.YYYY');

          if(this.rechnungsObjekts[i].einnahme){
            newChartData.y=this.rechnungsObjekts[i].summe;
            this.chartDataDonut.x+=this.rechnungsObjekts[i].summe;
          }else{
            this.chartDataDonut.y+=this.rechnungsObjekts[i].summe;
            newChartData.y=this.rechnungsObjekts[i].summe*-1; 
          }
          var u = parseFloat(i)-1;
          if(this.rechnungsObjekts[u]==undefined){
            this.chartData.push(newChartData);
          }else{
            if(this.rechnungsObjekts[u].einnahme){
              newChartData.y=this.rechnungsObjekts[i].summe+this.chartData[u+1].y;
            }else{
              newChartData.y=this.chartData[u+1].y+this.rechnungsObjekts[i].summe;
            }
            this.chartData.push(newChartData);    
          }
        }
        this.chartData.push({x:this.dateTo,y:this.calculateSaldo(this.Saldo)})
        this.series1.push(this.chartDataDonut.x,this.chartDataDonut.y)
      },
      format_date(value){
         if (value) {
           return moment(String(value)).format('DD.MM.YYYY')
          }
      },
      calculateSaldo(value){
        for(var i in this.rechnungsObjekts){
          if(this.rechnungsObjekts[i].einnahme){
             value+=this.rechnungsObjekts[i].summe;
          }else{
            value-=this.rechnungsObjekts[i].summe;
          }
        }
        return value;
      },
      updateBillByDate(){
        this.$store.dispatch('getRechnungsObjekteByDate',{
                  fromDate:this.dateFrom,
                  toDate:this.dateTo,
              });
      },
      styleSum(value){
        if(value.einnahme){
          var a = '+' + value.summe + '€';
          return a;
        }else{
           var b = '-' + value.summe + '€';
          return b;
        }
      },
    },
    created() {
      this.updateBillByDate();
      this.getData();
    },
    mounted() {
      if(localStorage.getItem('fromDate')!= null && localStorage.getItem('toDate')!= null){
        this.dateFrom=localStorage.getItem('fromDate');
        this.dateTo=localStorage.getItem('toDate');
      }
    },
     watch:{
    rechnungsObjekts:function(){
      this.getData();
    },
    dateFrom:function(){
        this.updateBillByDate();
        this.getData();
    },
    dateTo:function(){
        this.updateBillByDate();
        this.getData();
    },
  },
}
</script>

<style scoped>
.v-sheet.v-card {
    border-radius: 20px;
}
.NeuerBelegBTN{
  text-transform: capitalize;
  font-weight: 500;
}
.gruen{
    color:#09B760;
    font-weight: 700;
    font-size: 14px !important;
  }
  .blau{
    font-size: 14px !important;
    color:#007EEF ;
    font-weight: 700;
  }
.Saldo{
  color:#001B30;
  font-weight: bold;
  font-size: 16px !important;
}
.chartPie{
  left:0px;
}
.pieTitle{
  font-size: 14px !important;
  font-weight: 700;
}
.rechnungsList{
  height: 35vh !important;
  overflow-y:auto;
}
p{
  font-size: 12px !important;
  color:#001B30;
}
.MainDivider{
  border-width: medium;
  border-color: #001B30;
  background-color: #001B30;
}
.bottomMenuTab{
  font-size: 9px !important;
   text-transform: capitalize;
}
.chartLabelR{
  text-align: center;
  bottom: 80px;
  left: 20px;
  font-weight: 700;
}
.chartLabelL{
  right:20px;
  text-align: center;
  bottom: 80px;
  font-weight: 700;
}
.v-app-bar{
  border-top-left-radius: 40px !important;
  border-top-right-radius: 40px !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
  height: 10% !important;
  position: fixed;
  width:100vw !important;
  bottom:0 !important;
}
::v-deep .suche .v-label{
  font-size: 11px !important;
}
::v-deep .suche .v-icon{
  font-size: 16px !important;
}
::v-deep .datePicki .v-icon {
  font-size: 14px !important;
  color: #001B30;
}
::v-deep .datePicki .v-input__slot {
  padding-left:5px !important;
}
.v-list-item__subtitle{
  font-size:12px !important;
  color: #001B30 !important;
}
.v-divider{
  margin:0 !important;
}
::v-deep .v-input__control{
  min-height: auto !important;
}
.v-list-item__title{
  font-weight: 700 !important;
  font-size: 16px;
}
.v-text-field{
  font-size:11px !important;
}
.v-list-item__action-text{
  color: #001B30 !important;
}
.listBesch{
  
  font-weight: bold;
  
}
.einnahme{
  color:#09B760 !important;
}
.ausgabe{
  color:#007EEF !important;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
