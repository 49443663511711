import axios from 'axios';

const state={
    kundenObjekts:[]
}
const mutations={
    UPDATE_KUNDEN_OBJEKTE (state,payload){
        state.kundenObjekts=payload;
    },
    PATCH_KUNDENOBJEKT (state,kundenObjekt){
        state.kundenObjekt=kundenObjekt;
    },
    ADD_KUNDENOBJEKT(state, kundenObjekt) {
        state.kundenObjekt = kundenObjekt;
    }
}
const actions={
    getKundenObjekte({commit}){
        axios.get('/kunden')
            .then((response)=>{
                console.log(response);
                commit('UPDATE_KUNDEN_OBJEKTE', response.data);
            })
    },
    patchKundenObjekt({ commit }, kundenObjekt) {
        return new Promise((resolve, reject) => {
            axios.patch(`/kunden/${kundenObjekt.id}`,kundenObjekt)
                .then((response) => {
                    console.log(response.data);
                    commit('PATCH_KUNDENOBJEKT', response.data);
                    resolve(response)
                }).catch((error) => {
                    reject(error.response.data.errors)
                })
        })
    },
    addKundenObjekt({ commit }, kundenObjekt) {
        return new Promise((resolve, reject) => {
            axios.post(`/kunden`, kundenObjekt)
                .then((response) => {
                    console.log(response.data);
                    commit('ADD_KUNDENOBJEKT', response.data);
                    resolve(response)
                }).catch((error) => {
                    reject(error.response.data.errors)
                })
        })
    }
}
const getters={
    kundenObjekts:state=>state.kundenObjekts,
    kundenObjektById: state => id => state.kundenObjekts.find(kundenObjekt=>kundenObjekt.id === id),
}
const kundenModule={
    state,
    mutations,
    actions,
    getters
}

export default kundenModule;