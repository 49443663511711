import axios from 'axios';

const state={
    zahlungsmethodeObjekts:[]
}
const mutations={
    UPDATE_ZAHLUNGSMETHODE_OBJEKTE (state,payload){
        state.zahlungsmethodeObjekts=payload;
    },
    PATCH_ZAHLUNGSMETHODE (state,zahlungsmethodeObjekt){
        state.zahlungsmethodeObjekt=zahlungsmethodeObjekt;
    },
    ADD_ZAHLUNGSMETHODE(state, zahlungsmethodeObjekt) {
        state.zahlungsmethodeObjekt = zahlungsmethodeObjekt;
    }
}
const actions={
    getZahlunsmethodeObjekte({commit}){
        axios.get('/zahlunsmethode')
            .then((response)=>{
                //console.log(response);
                commit('UPDATE_ZAHLUNGSMETHODE_OBJEKTE', response.data);
            })
    },
    patchZahlunsmethodeObjekt({commit},zahlungsmethodeObjekt){
        axios.patch(`/zahlunsmethode/${zahlungsmethodeObjekt.id}`,zahlungsmethodeObjekt)
            .then((response)=>{
                console.log(response.data);
                commit('PATCH_ZAHLUNGSMETHODE', response.data);
            })
    },
    addZahlunsmethodeObjekt({ commit }, zahlungsmethodeObjekt) {
        axios.post(`/zahlunsmethode`, zahlungsmethodeObjekt)
            .then((response) => {
                console.log(response.data);
                commit('ADD_ZAHLUNGSMETHODE', response.data);
            })
    }
}
const getters={
    zahlungsmethodeObjekts:state=>state.zahlungsmethodeObjekts,
    zahlungsmethodeObjektById: state => id => state.zahlungsmethodeObjekts.find(zahlungsmethodeObjekt=>zahlungsmethodeObjekt.id === id),
}
const zahlungsmethodeModule={
    state,
    mutations,
    actions,
    getters
}

export default zahlungsmethodeModule;