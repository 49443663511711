import axios from 'axios';

const state={
    produktsObjekts:[]
}
const mutations={
    UPDATE_PRODUKTS_OBJEKTE (state,payload){
        state.produktsObjekts=payload;
    },
    PATCH_PRODUKTSOBJEKT (state,produktsObjekt){
        state.produktsObjekt=produktsObjekt;
    },
    ADD_PRODUKTSOBJEKT(state, produktsObjekt) {
        state.produktsObjekt = produktsObjekt;
    }
}
const actions={
    getProduktObjekte({commit}){
        axios.get('/produkte')
            .then((response)=>{
                //console.log(response);
                commit('UPDATE_PRODUKTS_OBJEKTE', response.data);
            })
    },
    patchProduktObjekt({ commit }, produktObjekt) {
        return new Promise((resolve, reject) => {
            axios.patch(`/produkte/${produktObjekt.id}`,produktObjekt)
                .then((response) => {
                    console.log(response.data);
                    commit('PATCH_PRODUKTSOBJEKT', response.data);
                    resolve(response)
                }).catch((error) => {
                    reject(error.response.data.errors)
                })
        })
    },
    addProduktObjekt({ commit }, produktsObjekt) {
        return new Promise((resolve, reject) => {
            axios.post(`/produkte`, produktsObjekt)
                .then((response) => {
                    console.log(response.data);
                    commit('ADD_PRODUKTSOBJEKT', response.data);
                    resolve(response)
                }).catch((error) => {
                    reject(error.response.data.errors)
                })
        })
    }
}
const getters={
    produktsObjekts:state=>state.produktsObjekts,
    produktsObjektById: state => id => state.produktsObjekts.find(produktsObjekt=>produktsObjekt.id === id),
}
const produktsModule={
    state,
    mutations,
    actions,
    getters
}

export default produktsModule;