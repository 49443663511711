<template>
    <div class="container mt-2">
        <div class="row">
            <v-btn 
                value="favorites" 
                class="btnZ ml-2 mb-3 mt-2" 
                color="white"
                rounded
                @click="$router.go(-1)"
            >
                <v-icon
                color="#007EEF"
                left
                >
                mdi-keyboard-backspace
                </v-icon>
                <span>Zurück</span>
            </v-btn>
        </div>
        <div class="row">
            <div class="col-12">
                    <div class="row no-gutters">
                        <h5 v-if="lieferantenObjekt==undefined" class="col-12 mt-2">
                            Neuen Lieferanten anlegen
                        </h5>
                    </div>
            </div>
            <div class="col-12">
                <div class="col-12">
                    <div class="row no-gutters">
                        <h5 class="card-title"  v-if="lieferantenObjekt===undefined">
                            <p class="inputBesch1 text-secondary mt-0 mb-1 float-left">Firma</p>
                            <v-textarea 
                                v-model="Firma" 
                                placeholder="Firma optional" 
                                class="form-control inputBesch1 py-0" 
                                rows="1"
                                row-height="10" 
                                auto-grow 
                                rounded
                            >
                            </v-textarea>
                            <p class="inputBesch1 text-secondary mt-0 mb-1 float-left">Vorname</p>
                            <v-textarea 
                                v-model="KundenVorname" 
                                placeholder="Vorname" 
                                class="form-control inputBesch1 pt-0" 
                                rows="1"
                                row-height="10" 
                                auto-grow 
                                rounded
                            >
                            </v-textarea>
                            <p class="inputBesch1 text-secondary mt-0 mb-1 float-left">Nachname</p>
                            <v-textarea 
                                v-model="KundenNachname" 
                                placeholder="Nachname" 
                                class="form-control inputBesch1 pt-0" 
                                rows="1"
                                row-height="10" 
                                auto-grow 
                                rounded
                            >
                            </v-textarea>
                            <p class="inputBesch1 text-secondary mt-0 mb-1 float-left">Straße und Nummer</p>
                            <v-textarea 
                                v-model="StraßeNummer" 
                                placeholder="Straße und Nummer" 
                                class="form-control inputBesch1 pt-0" 
                                rows="1"
                                row-height="10" 
                                auto-grow 
                                rounded
                            >
                            </v-textarea>
                            <p class="inputBesch1 text-secondary mt-0 mb-1 float-left">Postleitzahl</p>
                            <v-textarea 
                                v-model="Plz" 
                                placeholder="Postleitzahl" 
                                class="form-control inputBesch1 pt-0" 
                                rows="1"
                                row-height="10" 
                                auto-grow 
                                rounded
                            >
                            </v-textarea>
                            <p class="inputBesch1 text-secondary mt-0 mb-1 float-left">Ort</p>
                            <v-textarea 
                                v-model="Ort" 
                                placeholder="Ort" 
                                class="form-control inputBesch1 pt-0" 
                                rows="1"
                                row-height="10" 
                                auto-grow 
                                rounded
                            >
                            </v-textarea>
                            <p class="inputBesch1 text-secondary mt-0 mb-1 float-left">Land</p>
                            <v-textarea 
                                v-model="Land" 
                                placeholder="Land" 
                                class="form-control inputBesch1 pt-0" 
                                rows="1"
                                row-height="10" 
                                auto-grow 
                                rounded
                            >
                            </v-textarea>
                        </h5>
                        <h5 v-else>
                            <div v-if="lieferantenObjekt">
                                    <p class="inputBesch1 text-secondary mt-0 mb-1 float-left">Firma</p>
                                    <v-textarea 
                                        v-model="Firma" 
                                        placeholder="Firma optional" 
                                        class="form-control inputBesch1 py-0" 
                                        rows="1"
                                        row-height="10" 
                                        auto-grow 
                                        rounded
                                    >
                                    </v-textarea>
                                <p class="inputBesch1 text-secondary mt-0 mb-1 float-left">Vorname</p>
                                <v-textarea 
                                    v-model="KundenVorname" 
                                    placeholder="Vorname" 
                                    class="form-control inputBesch1 pt-0" 
                                    rows="1"
                                    row-height="10" 
                                    auto-grow 
                                    rounded
                                >
                                </v-textarea>
                                <p class="inputBesch1 text-secondary mt-0 mb-1 float-left">Nachname</p>
                                <v-textarea 
                                    v-model="KundenNachname" 
                                    placeholder="Nachname" 
                                    class="form-control inputBesch1 pt-0" 
                                    rows="1"
                                    row-height="10" 
                                    auto-grow 
                                    rounded
                                >
                                </v-textarea>
                                <p class="inputBesch1 text-secondary mt-0 mb-1 float-left">Straße und Nummer</p>
                                <v-textarea 
                                    v-model="StraßeNummer" 
                                    placeholder="Straße und Nummer" 
                                    class="form-control inputBesch1 pt-0" 
                                    rows="1"
                                    row-height="10" 
                                    auto-grow 
                                    rounded
                                >
                                </v-textarea>
                                <p class="inputBesch1 text-secondary mt-0 mb-1 float-left">Postleitzahl</p>
                                <v-textarea 
                                    v-model="Plz" 
                                    placeholder="Postleitzahl" 
                                    class="form-control inputBesch1 pt-0" 
                                    rows="1"
                                    row-height="10" 
                                    auto-grow 
                                    rounded
                                >
                                </v-textarea>
                                <p class="inputBesch1 text-secondary mt-0 mb-1 float-left">Ort</p>
                                <v-textarea 
                                    v-model="Ort" 
                                    placeholder="Ort" 
                                    class="form-control inputBesch1 pt-0" 
                                    rows="1"
                                    row-height="10" 
                                    auto-grow 
                                    rounded
                                >
                                </v-textarea>
                                <p class="inputBesch1 text-secondary mt-0 mb-1 float-left">Land</p>
                                <v-textarea 
                                    v-model="Land" 
                                    placeholder="Land" 
                                    class="form-control inputBesch1 pt-0" 
                                    rows="1"
                                    row-height="10" 
                                    auto-grow 
                                    rounded
                                >
                                </v-textarea>
                            </div>   
                        </h5>
                    </div>
                    <v-btn 
                        color="#35EF96"
                        col="12"
                        rounded
                        @click="save(lieferantenObjekt)"
                        >
                        <i class="far fa-save mr-1"></i>Abspeichern
                    </v-btn>
                </div>
            </div>
        </div> 
        <div class="container">
            <div v-if="lieferantenObjekt!=undefined" class="row mt-5">
                <div class="col-12 mb-3">
                    <h4 class="float-left ">Rechnungen</h4>
                </div>
                
                <div class="col-md-4 col-12 mt-2 " v-for="KundenRechnungen in KundenRechnungen"
                    :key="KundenRechnungen.id">
                        <div class="card ">
                            <div class="card-body">
                                <h5 class="card-title">Rechnungsnummer: 2020_10_{{rechnungsObjekt(KundenRechnungen.id).id}}</h5>
                                <h6 class="card-subtitle text-dark mt-1">Summe: {{rechnungsObjekt(KundenRechnungen.id).summe}} €</h6>
                                <p class="card-subtitle text-muted mt-1">Leistungsdatum von: {{dateFormat(rechnungsObjekt(KundenRechnungen.id).leistungsdatumFrom)}}</p>
                                <p class="card-subtitle text-muted mt-1">Leistungsdatum bis: {{dateFormat(rechnungsObjekt(KundenRechnungen.id).leistungsdatumTo)}}</p>
                                <ul class="actions">
                                <router-link :to="'/rechnung/'+KundenRechnungen.id" class="btn mx-2" data-tip="Anschauen" tag="button"><i class="fa fa-2x fa-search"></i></router-link>
                                </ul>
                            </div>
                        </div>
                </div>
            </div>
        </div>
       <div class="col-12">
            <v-snackbar
                v-model="snackbar.show"
                rounded="pill"
                :color="snackbar.color"
                :multi-line="snackbar.multi"
                class="snackbarText"
                @click="snackbar.show = false"
            >  
                {{snackbar.message}}
        </v-snackbar>
        </div> 
    </div>
</template>

<script>
import moment from 'moment';
export default {
    name:'LieferantenObjekt',
    props:['id'],
    components:{    
    },
    computed:{
        lieferantenObjekt(){
           if(this.id!="add"){
               return {...this.$store.getters.kundenObjektById(this.id)};
           }else{
               return undefined;
           }
        },
    },
    mounted() {
        this.datenladen();
    },
    data(){
        return{
             snackbar:{
                show: false,
                message: null,
                color: '#001854',
                multi:true
            },
            Firma:'',
            KundenVorname:'',
            KundenNachname:'',
            StraßeNummer:'',
            Plz:'',
            Ort:'',
            Land:'',
            datenladenBool:true,
            KundenRechnungen:'',
            istKunde:false,
            istLieferant:true
        }
    },
    methods: {
        datenladen(){
            if(this.datenladenBool){
                if(this.lieferantenObjekt!==undefined){
                    this.Firma=this.lieferantenObjekt.firma;
                    this.KundenVorname=this.lieferantenObjekt.vorname;
                    this.KundenNachname=this.lieferantenObjekt.nachname ;
                    this.StraßeNummer=this.lieferantenObjekt.strasse;
                    this.Plz=this.lieferantenObjekt.plz; 
                    this.Ort=this.lieferantenObjekt.ort; 
                    this.Land=this.lieferantenObjekt.land;
                    this.KundenRechnungen=this.lieferantenObjekt.rechnungen;                    
                 }
            }
            
        },
        save(lieferantenObjekt){
            if(this.id=="add"){
                this.$store.dispatch('addKundenObjekt',{
                    firma:this.Firma,
                    vorname:this.KundenVorname,
                    nachname:this.KundenNachname,
                    strasse:this.StraßeNummer,
                    plz:this.Plz,
                    ort:this.Ort,
                    land:this.Land,
                    istLieferant:this.istLieferant,
                    istKunde:this.istKunde, 
                }).then(()=>{
                      this.snackbar={
                          message:'Erfolgreich abgespeichert',
                          color:'#001854',
                          show:true,
                      }
                  })
                  .catch(error=>{ // eslint-disable-line no-unused-vars
                          this.snackbar={
                          message:'Ups da ist was schief gelaufen',
                          color:'red',
                          show:true,
                    }
                });
            }else{
                this.lieferantenObjekt.firma=this.Firma;
                this.lieferantenObjekt.vorname = this.KundenVorname;
                this.lieferantenObjekt.nachname = this.KundenNachname;
                this.lieferantenObjekt.strasse=this.StraßeNummer;
                this.lieferantenObjekt.plz=this.Plz; 
                this.lieferantenObjekt.ort=this.Ort; 
                this.lieferantenObjekt.land=this.Land; 
                this.$store.dispatch('patchKundenObjekt',lieferantenObjekt);
            }
            return this.bearbeiten=true;
        },
        rechnungsObjekt(v){
            if(this.id!="add"){
               return {...this.$store.getters.rechnungsObjektById(v)};
           }else{
               return undefined;
           }
        },
        dateFormat(date){
            let newFormat=moment(date).format('DD.MM.YYYY');
            return newFormat;
        }

    }
}
</script>

<style>
</style>