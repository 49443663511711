<template>
    <div>
        <v-tabs-items
            :value="Logintabo"
            touchless
        >
            <v-tab-item
            >
                <div class="container col-9 " v-if="!isEingeloggt">
                    <div class="row">
                        <div class="col-md-3 pt-0"></div>
                        <div class="col-12 col-md-6">
                            <h6 class="Titel blau">Konto erstellen</h6>
                            <div>
                                <p class="text-secondary float-left mb-1">Deine Emailadresse</p>
                                <v-textarea v-model="username" placeholder="E-Mail" class="form-control login" rows="1"
                                row-height="10" auto-grow  filled rounded></v-textarea>
                                <p class="text-secondary float-left  mb-1">Passwort erstellen</p>
                                <v-textarea v-model="password" placeholder="Passwort" class="form-control login" rows="1"
                                row-height="10" auto-grow filled rounded></v-textarea>
                                <p class="text-secondary float-left  mb-1">Passwort wiederholen</p>
                                <v-textarea v-model="password" placeholder="Passwort" class="mt-0 form-control login" rows="1"
                                row-height="10" auto-grow filled rounded></v-textarea>
                                <v-btn 
                                    class="BtnGross mr-2 mb-5 "
                                    color="#35EF96"
                                    rounded
                                    @click="submit"
                                    >
                                    Konto Erstellen
                                </v-btn>
                            </div>
                            <div>
                                <v-btn 
                                    small
                                    text
                                    plain
                                    color="#007EEF"
                                    @click="Logintabo=1 "
                                >
                                <p><u>Ich habe bereits ein Konto</u></p>
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container" v-else>
                    <div class="row">
                        <div class="col-md-3"></div>
                        <div class="col-12 col-md-6">
                            <v-card class="mb-2 bgcolor1">
                                <v-card-title  color="green">Du Bist Eingeloggt</v-card-title>
                            </v-card>
                            <v-card>
                                <v-card-title v-model="username" class="mr-2 col-4" type="text">Hallo {{this.$store.state.username}}</v-card-title>
                                <v-card-actions><v-btn  @click="ausloggen"  color="error" class="btn" >Abmelden</v-btn></v-card-actions>
                            </v-card>  
                        </div>
                    </div>
                </div>
            </v-tab-item>
            <v-tab-item>
                <div class="container col-9 " v-if="!isEingeloggt">
                    <div class="row">
                        <div class="col-md-3 pt-0"></div>
                        <div class="col-12 col-md-6">
                            <h6 class="Titel blau">Login</h6>
                            <div>
                                <p class="text-secondary float-left mb-1">Deine Emailadresse</p>
                                <v-textarea v-model="username" placeholder="E-Mail" class="form-control login" rows="1"
                                row-height="10" auto-grow  filled rounded></v-textarea>
                                <p class="text-secondary float-left  mb-1">Passwort</p>
                                <v-text-field v-model="password" type="password" placeholder="Passwort"  class="form-control login" rows="1"
                                row-height="10" auto-grow filled rounded></v-text-field>
                                <v-btn 
                                    class="BtnGross mr-2 mb-5 "
                                    color="#35EF96"
                                    rounded
                                    @click="submit"
                                    >
                                    Einloggen
                                </v-btn>
                            </div>
                            <div>
                                <v-btn 
                                    small
                                    text
                                    plain
                                    color="#007EEF"
                                    @click="Logintabo=0"
                                >
                                <p><u>Ich habe noch kein Konto</u></p>
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container" v-else>
                    <div class="row">
                        <div class="col-md-3"></div>
                        <div class="col-12 col-md-6">
                            <v-card class="mb-2 bgcolor1">
                                <v-card-title  color="green">Du Bist Eingeloggt</v-card-title>
                            </v-card>
                            <v-card>
                                <v-card-title v-model="username" class="mr-2 col-4" type="text">Hallo {{this.$store.state.username}}</v-card-title>
                                <v-card-actions><v-btn  @click="ausloggen"  color="error" class="btn" >Abmelden</v-btn></v-card-actions>
                            </v-card>  
                        </div>
                    </div>
                </div>
            </v-tab-item>
        </v-tabs-items>
        <v-tabs
            grow
            icons-and-text
            hide-slider
            color="#001B30"
            v-model="Logintabo"
            class="rechnungTabs d-none"
        >
            <v-tab
                class="rechnungErstellung"
            >
            </v-tab>
            <v-tab
                class="rechnungErstellung"
            >  
            </v-tab> 
        </v-tabs>
        
        <div class="container">
            <v-snackbar
                :timeout="4000"
                v-model="snackbar"
                >
                {{ text }}
                <v-btn 
                    @click="snackbar = false"
                    class="btn"
                >
                    Close
                </v-btn>
            </v-snackbar> 
        </div>
    </div>
</template>

<script>

// import { mapGetters } from 'vuex'
export default {
    name:"Login",
    data() {
        return {
            Logintabo:null,
            username:'',
            password:'',
            error: '',
            snackbar:false,
            text:'',
            isEingeloggt:'false'
            
        }
    },
    watch:{
    Logintabo: function(){
        console.log(this.Logintabo)
    }
  },
    methods: {
        submit () {
            this.$store.dispatch("login",{
                username:this.username,
                password:this.password 
            }).catch((error) => {
                this.snackbar=true;
                this.error=error.response;
                this.text='Benutzername oder Passwort ist falsch'
             }).then(() => {
                 if(this.error.status==undefined){
                    this.$router.push({ path: '/' }) 
                }
             })
             this.error='';   
             },
        ausloggen(){
            localStorage.removeItem('token')
            this.$store.dispatch("logOut").then(()=>{
                 this.$router.push('/login');
                 this.isEingeloggt=false;
            })
        }       
        },
        mounted() {
            if(this.$store.state.token==null){
                 this.isEingeloggt=false;
            }
        },
}
</script>

<style scoped>
    a{
        color:aliceblue !important;
    }
    p{
        font-size: 12px;

    }

    a:hover{
        opacity: .7;
        transition:all .3s ease 0;
    }
    .Titel{
        font-weight: 600;
    }
    .v-textarea{
        color: red !important;
    }
    ::v-deep .v-label {
    font-size: 12px !important;
    margin-bottom: 10px !important;
    }
   ::v-deep .v-input__slot{
        font-size: 12px;
        min-height: 10px !important;
        height: 30px !important;
    }
    ::v-deep .v-textarea.v-text-field--enclosed textarea{
        margin-top: 0px !important;
    }
    ::v-deep .v-btn{
        font-weight: 600;
        text-transform: inherit;
    }
    .LoginTabs .v-tabs-bar{
        height: 10px !important;
    }
</style>