<template>
  <v-app id="app" data-app>
    <v-navigation-drawer 
      app
      v-model="drawer"
      v-bind:width="330"
      >
        <BurgerBar v-on:changeDrawer="updateDrawer($event)" :close="drawer"/>
    </v-navigation-drawer>

  <v-app-bar app elevate-on-scroll>
          <v-tabs
          class="ml-n9"
          color="#001854"
          grow
          slider-color=white
          icons-and-text
          >
            <v-tab id="navigation-icon" v-if="$route.path !=='/login' && mobileView" class="ml-5">
                <v-app-bar-nav-icon color="#001854" @click="drawer = !drawer"></v-app-bar-nav-icon>
            </v-tab>
            
            <v-tab class="logo" to="/" v-if="$route.path !=='/login'">Receibra</v-tab>
            <v-tab v-else class="ml-4 Receibra" disabled>Receibra</v-tab>
            <v-tab class="newRechnButton" to="/rechnung/add" v-if="$route.path !=='/login'">Neue Rechnung<v-icon color="#001854">mdi-file-plus-outline</v-icon></v-tab>
            <Navbar
              v-if="$route.path !=='/login' && !mobileView"
            />
          </v-tabs>
  </v-app-bar>
  <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
  </v-main>
  <v-footer app>
  </v-footer>
  </v-app>
</template>

<script>
import BurgerBar from './components/Navigation/BurgerBar.vue'
import Navbar from './components/Navigation/Navbar.vue'
// import VBurger from './components/Navigation/V-burger.vue'
import {mapGetters} from 'vuex'

export default {
    name:'App',
    data() {
      return {
        mobileView:false,
        showNavigation:false,
        slide:false,
        drawer: false
      }
    },
    methods: {
      handleView(){
        this.mobileView=window.innerWidth<=800;
      },
      updateDrawer(updatedDrawer){
        this.drawer=updatedDrawer
      }
    },
    created() {
      this.handleView();
      window.addEventListener('resize',this.handleView);
    },
    components:{
      Navbar,
      BurgerBar
    },
  computed:{
    ...mapGetters({
       getToken:'getToken'
    })
  },
  watch:{
    $route: function(){
        if(this.getToken){
          // this.$store.dispatch('getRechnungsObjekte',this.getToken);
          this.$store.dispatch('getKundenObjekte',this.getToken);
          this.$store.dispatch('getProduktObjekte',this.getToken);
      }
    }
  },
  beforeCreate () {
    if(this.$route.path!=='/login'){
      if (!this.$store.state.token) {
       this.$router.push('/login')
      }
    }
  },
}
</script>

<style>
@import url(http://fonts.googleapis.com/css?family=Roboto:400,400italic,500,500italic,700,700italic,900,900italic,300italic,300,100italic,100);
#app:not(#navigation-icon) {
  font-family:'Montserrat', 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative !important;
  }
  .logo{
     text-transform: capitalize;
     font-weight: 600;
  }
.newRechnButton{
  font-size: 6px !important;
  text-transform: capitalize;
}
.container{
  animation-fill-mode: forwards !important;
  /* animation-timing-function: ease-in-out !important; */
}
.v-snack__content{
  text-align: center !important;
}
.snackbarText{
  font-size: 12px !important;
  text-align: center !important;
}
.Receibra{
  color: #001B30 !important;
  font-weight: 600;
  text-transform: capitalize;
  opacity: 1;
  font-size: 16px;

}
#nav {
  padding: 0px 10px 30px 10px;
}
li{
  font-weight: 300;
}
.radioGroup .v-input__slot{
  box-shadow: rgb(0 0 0 / 0) 0px 0px 0px 0px !important;
}
h5{
  color:#001854;
  font-size: 20px;
  font-weight: 600 !important;
}
.inputBesch1{
        font-size: 16px;
        font-weight: 400;
        width: 100%;
        text-align: left;
        color: #001854 !important;
}
.inputBesch{
    width: 100%;
    text-align: left;
    margin-bottom: 5px !important;
    color: #001854 !important;
}
p{
  font-size: 16px;
}
h6{
  font-size: 14px;
  font-weight: 400;
}
.v-file-input label{
  font-size:12px !important;
}
.v-file-input__text{
  font-size:12px !important;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
.color1{
  color:rgb(62, 158, 122) !important;
}
.iconC{
  background-color:rgb(62, 158, 122) !important;
}
.iconC:hover,.iconC:before {
  background-color:rgb(62, 158, 122) !important;
}

.bgcolor1{
 background-color:rgb(62, 158, 122)  !important;
}
.v-divider{
  margin:0 !important;
}
.v-navigation-drawer__content{
  background-color: #001B30;
  color:white;
}
.theme--light.v-label{
  color:#001B30 !important;
}
.theme--light.v-text-field--filled > .v-input__control > .v-input__slot{
  background-color: white !important;
}
.v-input input{
  max-height: 32px !important;
}
.summeKundNeg{
    color:#007EEF;
    font-weight: 600 !important;
    font-size: 16px;
}
#navigation-icon{
  padding: 5px;
  float: left;
  cursor: pointer;
}
.v-tab:not(.v-tab--active){
  color:#001854 !important;
}
.bgcolor4{
  background:rgb(62, 158, 122);
}.bgcolor4:hover{
  background:#31506B;
}
.bgcolor1nH{
  background: #31506B;
}
.bgcolor3H{
  background:aliceblue;
}
.bgcolor3H:hover{
  background:#B4B4D1;
}
.bgcolor2{
  background:#B4B4D1;
}
.bgcolor3{
  background:aliceblue;
}
.bgcolor1:hover{
  background:rgb(62, 158, 122);
}
.color1{
  color:#31506B;
}
.color1nH{
  color:#31506B;
}
.color1nH:hover{
  color: #31506B;
}
.color1H{
  color:#31506B;
}
.color1H:hover{
  color:aliceblue;
}
.color1:hover{
  color:rgb(62, 158, 122);
}
.color2{
  color:aliceblue;
}
.color2:hover{
  color:aliceblue;
}
.color3{
  color:aliceblue;
} 

.rechnungTabs .v-tabs-bar{
        height: 32px !important;
    }
    .kundenListIcon p{
      font-size: 8px !important;
    }

  .gruen{
    color:#09B760;
  }
  .blau{
    color:#007EEF ;
  }
  .summeKund{
    color:#09B760;
    font-weight: 600 !important;
    font-size: 16px;
  }
  .kundenListIcon{
      box-shadow: none !important;
      background-color: white !important;
  }

  .kundenListIcon p{
    font-size: 8px !important;
  }
  .titelLi{
    font-weight: 600 !important;
    color:#001B30;
  }

</style>
