import * as types from './mutations-types'
import * as http from '../http/index'
import axios from 'axios'


// export const setToken = ({ commit }, payload) => {
//     commit(types.TOKEN, payload)
//   }

export const login = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
      http.postLogin(payload)
        .then(function (result) {
          commit(types.TOKEN, result.data['token'])
          commit(types.USERNAME, payload.username)
          const token = result.data['token']
          localStorage.setItem('token',token)
          axios.defaults.headers.common['Authorization'] = token
          resolve(result)
        }).catch(function (error) {
          // console.log(error.response)
          reject(error)
        })
    })
}
export const logOut = ({commit}) => {
  return new Promise((resolve) => {
    localStorage.removeItem('token');
     axios.defaults.headers.common['Authorization'] = null
    commit(types.TOKEN, null);
    resolve();
 })
}

  