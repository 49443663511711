import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Rechnungsuebersicht from '../views/Rechnungsuebersicht'
import RechnungsObjekt from '../components/Rechnungen/RechnungsObjekt.vue'
import Kundenuebersicht from '../views/Kundenuebersicht.vue'
import KundenObjekt from '../components/Kunden/KundenObjekt.vue'
import Lieferantenuebersicht from '../views/Lieferantenuebersicht.vue'
import LieferantenObjekt from '../components/Lieferanten/LieferantenObjekt.vue'
import ProduktObjekt from '../components/Produkte/ProduktObjekt.vue'
import Produktuebersicht from '../views/Produktuebersicht.vue'
import Login from '../components/Login/Login.vue'
import axios from 'axios'

Vue.use(VueRouter)
axios.defaults.baseURL='https://rechnungsverwaltung.stall-unserweidlinger.at/api/v1/'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/rechnungsuebersicht',
    name: 'Rechnungsuebersicht',
    component:Rechnungsuebersicht
  },
  {
    path:'/rechnung/:id',
    component:RechnungsObjekt,
    props:true
  },
  {
    path:'/rechnung/add',
    component:RechnungsObjekt,
    props:true
  },
  {
    path: '/kundenuebersicht',
    name: 'Kundenuebersicht',
    component:Kundenuebersicht
  },
  {
    path:'/kunde/:id',
    component:KundenObjekt,
    props:true
  },
  {
    path:'/kunde/add',
    component:KundenObjekt,
    props:true
  },
  {
    path: '/produktuebersicht',
    name: 'Produktuebersicht',
    component:Produktuebersicht
  },
  {
    path:'/produkt/:id',
    component:ProduktObjekt,
    props:true
  },
  {
    name:'Login',
    path:'/login',
    component:Login,
    props:true
  },
  {
    name:'Logout',
    path:'/logout',
    component:Login,
    props:true
  },
  {
    path: '/lieferantenuebersicht',
    name: 'Lieferantenuebersicht',
    component:Lieferantenuebersicht
  },
  {
    path:'/lieferant/:id',
    component:LieferantenObjekt,
    props:true
  },
  {
    path:'/lieferant/add',
    component:LieferantenObjekt,
    props:true
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requires === 'auth')) {
  // if not logged in
    if (!(localStorage.getItem('auth_token') || undefined)) {
      next({
        name: 'Login'
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.requires === 'guest')) {
  // if logged in
    if (localStorage.getItem('auth_token') || undefined) {
      next({
        name: 'Home'
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
