<template>
  <div class="sideMenu">
    <v-list >
      <v-tabs	
            grow
            color="white"
            background-color="#001B30"
            left
            >
            <v-tab id="navigation-icon" class="">
               <v-btn rounded small outlined class="mt-2 sideMenuButton">Unternehmen</v-btn>
            </v-tab>
            <v-tab id="navigation-icon" class="">
                <v-btn rounded small outlined class="mt-2 sideMenuButton">Privat</v-btn>
            </v-tab>
            <v-tab id="navigation-icon" class="">
                <v-icon @click="closeSideMenu">mdi-window-close</v-icon>
            </v-tab>
          </v-tabs>
          <v-list-item-title class="text-left ml-4 mt-8 font-weight-bold">Verwaltung</v-list-item-title>
    </v-list> 
    <div>
      <v-list
          dense
          nav
          v-for="item in items"
            :key="item.title"
        >
          <v-list-item
            link
            :to="item.route"
            >
            <v-list-item-icon class="mr-0">
              <v-icon  >{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="text-left ml-3">
              <v-list-item-title >{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="m-0 white"></v-divider>
        </v-list>
    </div>
    <div class="fixed-bottom" >
      <v-list
        dense
        nav
        v-for="accountItem in accountItems"
          :key="accountItem.title"
        >
          <v-list-item
            link
            :to="accountItem.route"
            >
            <v-list-item-icon class="mr-0">
              <v-icon  >{{ accountItem.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="text-left ml-3">
              <v-list-item-title >{{ accountItem.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        <v-divider class="m-0 white"></v-divider>
      </v-list>
    </div>
  </div>
</template>

<script>
// import { TastyBurgerButton } from 'vue-tasty-burgers'
  export default {
    name:'BurgerBar',
    props:{
      close:Boolean
    },
    data: () => {
      return {
        items:[
          { title: 'Kundenverwaltung', icon: 'mdi-account-multiple',route:'/kundenuebersicht' },
          { title: 'Rechnungsverwaltung', icon: 'mdi-file-document-outline', route:'/rechnungsuebersicht' },
          { title: 'Produktverwaltung', icon: 'mdi-cart-outline', route:'/produktuebersicht' },
          { title: 'Lieferantenverwaltung', icon: 'mdi-truck-outline', route:'/lieferantenuebersicht' },
        ],
        accountItems:[
          { title: 'Accounteinstellungen', icon: 'mdi-account-circle-outline',route:'/Accounteinstellungen' },
          { title: 'Allgemeine Einstellungen', icon: 'mdi-cog', route:'/Allgemeineeinstellungen' },
          { title: 'Dashboardindividualisierung', icon: 'mdi-brush', route:'/DashboardInd' },
        ],
      }
    },
     components: {
    // 'tasty-burger-button': TastyBurgerButton
    },
    methods: {
        closeSideMenu(){
          this.$emit('changeDrawer', false)
        }
    },
  }
</script>

<style scoped>
 .sideMenuButton{
   font-size:10px !important;
   color: white !important;
 }

 .v-icon{
   color:white !important;
 }
 .theme--light.v-icon{
   color: white;
 }
 .v-list-item__icon{
    color:white;
  }
  .v-list-item__title{
    color:white;
    font-weight: 300 !important;
  }
</style>

