<template>
    <div class="container">
        <h5 class="">Übersicht deiner Kunden</h5>
        <h6>nach Einnahmen 2021</h6>
            <v-list 
                two-line
                class="kundenList"
            >
                <v-list-item-group
                multiple
                >
                  <div 
                    v-for="kundenObjekt in kundenObjekts"
                    :key="kundenObjekt.id" >                                
                    <v-list>
                      <v-list-group
                        
                        append-icon
                      >
                        <template v-slot:activator>
                          <v-list-item-content>
                            <v-list-item-title class="text-left titelLi" v-text="kundenObjekt.firma"></v-list-item-title>
                            <v-list-item-subtitle class="text-left" v-text="kundenObjekt.vorname +' ' + kundenObjekt.nachname "></v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-list-item-title class="summeKund" v-text="'+' +getSumme(kundenObjekt)+'€'"></v-list-item-title>
                          </v-list-item-action>
                        </template>
                        <v-list-item>
                          <v-list-item-content>
                            <div class="row kundenListIcon">
                              <div class="col-4">
                                <v-btn
                                  fab
                                  x-small
                                  :to="'/kunde/'+ kundenObjekt.id"
                                  class="kundenListIcon"
                                >
                                  <v-icon class="kundenListIcon" color="#007EEF">mdi-eye-outline</v-icon>
                                </v-btn>
                                <p class="blau">Kunde ansehen</p>
                              </div>
                              <div class="col-4">
                                <v-btn
                                  fab
                                  x-small
                                  :to="'/kunde/'+kundenObjekt.id"
                                  class="kundenListIcon"
                                >
                                  <v-icon class="kundenListIcon" color="#09B760">mdi-pencil-outline</v-icon>
                                </v-btn>
                                <p class="gruen">Kunde bearbeiten</p>
                              </div>
                              <div class="col-4">
                                <v-btn
                                  fab
                                  x-small
                                  class="kundenListIcon"
                                >
                                  <v-icon class="kundenListIcon">mdi-close</v-icon>
                                </v-btn>
                                <p>Kunde löschen</p>
                              </div>
                            </div>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-group>
                    </v-list>
                    <v-divider></v-divider>
                  </div>
              </v-list-item-group>
          </v-list>
          <v-btn 
            class="NeuerBelegBTN my-6"
            to="/kunde/add"
            rounded
            color="#35EF96"
          >
          + Neuer Kunde</v-btn>
    </div>
</template>

<script>
export default {
    name: 'Kunden',
    created() {
        // this.$store.dispatch('getKundenObjekte');
    },
    computed:{
      kundenObjekts(){
        return this.$store.getters.kundenObjekts.filter(kundenObjekt=>kundenObjekt.istLieferant===false && kundenObjekt.istKunde===true )
      }
    },
    methods: {
      getSumme(ob){
        var summeRechnung=0;
        for(var i in ob.rechnungen){
          if(ob.rechnungen[i].einnahme){
            summeRechnung+=ob.rechnungen[i].summe;
          }else{
            summeRechnung-=ob.rechnungen[i].summe;
          }
        }
        return summeRechnung;
      }
    },
    
}
</script>

<style scoped >
    .actions {
    width:150px;
    padding:0;
    margin:0;
    list-style:none;
    opacity:0;
    transform:translateY(-50%) translateX(-50%);
    position:absolute;
    top:60%;left:50%;
    z-index:1;
    transition:all .3s ease 0s
  }
  .card:hover .actions {
    opacity: 1;
    top: 50%;
  }
  .card .actions li {
    display: inline-block;
  }
  .kundenList{
  /* height: 35vh !important; */
  overflow-y:auto;
}
  .card .actions li button {
    color: #fff;
    background-color: rgb(52,73,94);
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    height: 40px;
    width: 40px;
    margin: 0 2px;
    display: block;
    position: relative;
    transition: all .3s ease-in-out;
    padding: 0;
  }

  .card .actions li button:hover {
    color: #fff;
    background-color: rgb(65,184,131);
  }
  .v-list-item__content{
    padding: 0px !important;
  }
  .v-list{
    padding: 0px !important;
  }
  .card .actions li button:before {
    content: attr(data-tip);
    color: #fff;
    background-color: rgb(65,184,131);
    transition: all .3s ease-in-out;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 20px;
    padding: 1px 5px;
    white-space: nowrap;
    opacity: 0;
    transform: translateX(-50%);
    position: absolute;
    left: 50%;
    top: -30px
  }

  .card .actions li button:hover:before {
    opacity: 1
  }
  .card-title{
    font-weight: 300;
  }
  
  

    

</style>
